.login-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.login-modal {
  background: white;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  width: 90%;
  position: relative;
  max-height: 90vh;
  overflow-y: auto;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 15px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #777;
}

.login-options {
  text-align: center;
}

.login-options h2 {
  margin-bottom: 10px;
  color: #333;
}

.login-options p {
  color: #666;
  margin-bottom: 25px;
}

.login-option-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 12px;
  margin-bottom: 15px;
  border-radius: 4px;
  border: 1px solid #ddd;
  background: white;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.2s;
}

.login-option-btn span {
  margin-left: 10px;
}

.google-btn:hover {
  background-color: #f1f1f1;
}

.email-btn {
  background-color: #4285f4;
  color: white;
  border: none;
}

.email-btn:hover {
  background-color: #3367d6;
}

.login-divider {
  position: relative;
  margin: 25px 0;
  text-align: center;
}

.login-divider::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #ddd;
  z-index: 1;
}

.login-divider span {
  position: relative;
  background-color: white;
  padding: 0 10px;
  color: #666;
  z-index: 2;
}

.register-btn {
  width: 100%;
  padding: 12px;
  border: 2px solid #4CAF50;
  background: white;
  color: #4CAF50;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

.register-btn:hover {
  background-color: #f1f9f1;
} 