.container {
  max-width: 448px;
  margin: 0 auto;
  padding: 20px;
}

.scoreboard-wrapper {
  max-width: 448px;
  margin: 0 auto;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.scoreboard-table {
  width: 100%;
  border-collapse: collapse;
}

.scoreboard-table th,
.scoreboard-table td {
  padding: 12px 16px;
  border: none;
  background: transparent;
}

.scoreboard-table th {
  color: #333;
  font-weight: normal;
}

.scoreboard-table tr {
  border-bottom: 1px solid #eee;
}

.scoreboard-table tr:last-child {
  border-bottom: none;
}

.align-right {
  text-align: right;
}

.align-left {
  text-align: left;
}

/* Remove any additional borders or backgrounds */
.scoreboard-table thead tr {
  background: transparent;
}

.page-title {
  color: rgb(102, 102, 102);
  text-align: center;
  font-size: 48px;
  margin: 40px 0;
  font-weight: normal;
}

.page-description {
  text-align: center;
  font-size: 24px;
  margin: 20px 0 40px;
  font-weight: normal;
  color: black;
}

.login-prompt {
  color: #666;
  margin: 20px 0;
  font-style: italic;
}

.scoreboard-table tr:hover {
  background-color: #f9f9f9;
}

/* Optional: Add some padding to the right-aligned columns */
.scoreboard-table th.align-right,
.scoreboard-table td.align-right {
  padding-right: 20px;
} 