.auth-form-container {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.auth-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.back-btn {
  background: none;
  border: none;
  cursor: pointer;
  margin-right: 15px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
  color: #333;
}

.form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.form-group small {
  display: block;
  margin-top: 5px;
  color: #777;
  font-size: 12px;
}

.auth-btn {
  width: 100%;
  padding: 12px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 10px;
}

.auth-btn:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.text-btn {
  background: none;
  border: none;
  color: #4CAF50;
  padding: 8px;
  margin-top: 15px;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  width: 100%;
}

.error-message {
  color: #d9534f;
  margin-bottom: 15px;
  font-size: 14px;
}

.success-message {
  text-align: center;
  color: #333;
  margin: 20px 0;
}

.verification-message {
  text-align: center;
  margin: 20px 0;
}

/* For the standalone pages */
.auth-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
}

.auth-container {
  background: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 400px;
}

.auth-container h2 {
  text-align: center;
  margin-bottom: 25px;
}

.loading-spinner {
  text-align: center;
  margin: 30px 0;
} 